import logo from './logo.svg';
import './App.css';
import fonde from './imgs/fonde.jpeg';
import CalendarComponent from './CalendarComponent';

const backgroundStyle = {
  backgroundImage: 'url('+fonde+')',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  minHeight: '100vh', // Asegúrate de cubrir toda la altura de la ventana
  margin: 0,
};

function App() {
  return (
    <div style={backgroundStyle} className="App">
      <CalendarComponent />
    </div>
  );
}

export default App;
