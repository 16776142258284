// src/components/CalendarComponent.js
import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import alis from './imgs/Alis.png';
import aris from './imgs/Aris.png';
import cata from './imgs/Cata.png';
import chino from './imgs/Chino.png';
import claus from './imgs/Claus.png';
import facu from './imgs/Facu.png';
import gabi from './imgs/Gabiesman.png';
import heis from './imgs/Heis.png';
import jorge from './imgs/Jorgino.png';
import richard from './imgs/Richardo.png';
import tutu from './imgs/Tutu.png';
import valen from './imgs/Valen.png';
import marian from './imgs/Marian.png';
import martin from './imgs/Martin.png';
import vicko from './imgs/Vicko.png';
import suans from './imgs/Suans.png';
import empty from './imgs/Empty.png';
import { es } from 'date-fns/locale';
import { format } from 'date-fns';

import './CalendarComponent.css'; // Importar el archivo CSS para el calendario

const today = new Date();

const CalendarComponent = () => {
    const [date, setDate] = useState(new Date("09/01/2024")); 
    const [selectedDates, setSelectedDates] = useState({
        "Sun Sep 01 2024": empty,
        "Mon Sep 02 2024": jorge,
        "Tue Sep 03 2024": heis,
        "Wed Sep 04 2024": gabi,
        "Thu Sep 05 2024": tutu,
        "Fri Sep 06 2024": claus,
        "Sat Sep 07 2024": alis,
        "Sun Sep 08 2024": empty,
        "Mon Sep 09 2024": vicko,
        "Tue Sep 10 2024": richard,
        "Wed Sep 11 2024": valen,
        "Thu Sep 12 2024": chino,
        "Fri Sep 13 2024": empty,
        "Sat Sep 14 2024": suans,
        "Sun Sep 15 2024": marian,
        "Mon Sep 16 2024": empty,
        "Tue Sep 17 2024": martin,
        "Wed Sep 18 2024": empty,
        "Thu Sep 19 2024": cata,
        "Fri Sep 20 2024": empty,
        "Sat Sep 21 2024": empty,
        "Sun Sep 22 2024": empty,
        "Mon Sep 23 2024": aris,
        "Tue Sep 24 2024": empty,
        "Wed Sep 25 2024": empty,
        "Thu Sep 26 2024": facu,
        "Fri Sep 27 2024": empty,
        "Sat Sep 28 2024": empty,
        "Sun Sep 29 2024": empty,
        "Mon Sep 30 2024": empty,
    });
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (newDate) => {
        setDate(newDate);
    };

    const filterImg = (imgUrl) => {
        if (imgUrl.includes("base64")) return "---";
        return imgUrl.substring(14).split(".")[0]
    }

    const tileContent = ({ date, view }) => {
        const dateKey = date.toDateString();
        
        var classDay = "calendar-tile-content";
        if(today.getDate() == date.getDate() && today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear()) {classDay += " today-effect"}
        else if (date.getTime() < today.getTime()) {classDay += " red-image"};
        

        if (view === 'month' && selectedDates[dateKey]) {

            return (
                <div className={classDay + " calendar-tile-content"}>
                    <img src={selectedDates[dateKey]} alt="Selected" />
                    <span className="calendar-day-number">{format(date, 'd')}</span>
                    <span className='name-calendar'>{filterImg(selectedDates[dateKey])}</span>
                </div>
            );
        }
        return (
            <div className={classDay + " calendar-tile-content transparent  "}>
                    <img src={empty} alt="Selected" />
                    <span className="calendar-day-number">{format(date, 'd')}</span>
                    <span>---</span>
                </div>
        );
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="list-container p-3 border rounded shadow-sm">
                        <h1>Adivinamos la Fecha de Nacimiento de Emma</h1>
                        <p>Entras al sorteo apostando <strong>$1000</strong>.</p>
                        <ul>
                            <li><strong>Regla 1:</strong> Decir una fecha probable.</li>
                            <li><strong>Regla 2:</strong> Tolerancia de acierto un día para atrás y otro para adelante. Ejemplo: si digo 19, gano si nace el 18 o 20.</li>
                            <li><strong>Regla 3:</strong> Si alguien acierta específicamente la fecha, no se respeta la Regla 2.</li>
                            <li><strong>Regla 4:</strong> Si por tolerancia ganan varios, se reparte el premio.</li>
                            <li><strong>Regla 5:</strong> Si nadie acierta ni por acierto ni por tolerancia, gana Emma un regalo.</li>
                            <li><strong>Regla 6:</strong> Si decides una fecha, no puedes cambiarla.</li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="calendar-container border rounded shadow-sm">
                        <Calendar
                            className="calendar"
                            onChange={handleDateChange}
                            value={date}
                            tileContent={tileContent}

                        />
                        <div>
                            {Object.entries(selectedDates).map(([date, imageUrl]) => (
                                <div key={date}>
                                    {/*<h3>{date}</h3>
            <img src={imageUrl} alt="Selected" style={{ width: '100px' }} />*/}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default CalendarComponent;